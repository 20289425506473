/* eslint-disable react/prop-types */
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';

import { Box, Flex, Grid, H1, Inner, Link, Outer } from '../components/styled';
import { headerHeight } from '../components/theme/measurements';
import { CMS_COMPONENTS, CMS_SHORTCODES } from '../cms';
import BaseLayout from '../components/layout/BaseLayout';
import BlogStyleWrap from '../components/blog/style-wrap';
import Footer from '../components/footer';
import Header from '../components/header';
import LatestPosts from '../components/blog/latest-posts';
import * as Social from '../images/social/social-icons';

const SocialIconWrap = (props) => (
  <Box ml="6px" fontSize={26} color="indigo" sx={{ '&:hover': { color: 'blue' } }} {...props} />
);

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      id
      body
      excerpt(pruneLength: 160)
      frontmatter {
        title
        description
        slug
        date(formatString: "MMMM D, YYYY")
        headerImage {
          childImageSharp {
            header: fluid(maxHeight: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
            sharing: fixed(width: 1000, height: 500, cropFocus: CENTER) {
              src
            }
          }
        }
      }
    }
  }
`;

const BlogPostTemplate = ({ location, data, pageContext }) => {
  const post = data.mdx;
  const { previous, next } = pageContext;

  const { title, description, date, headerImage } = post.frontmatter;

  return (
    <BaseLayout
      seoProps={{
        title,
        description: description || post.excerpt,
        imagePublicURL: headerImage ? headerImage.childImageSharp.sharing.src : null,
      }}
    >
      <Box as="main" pt={headerHeight}>
        <Header bg="indigo" />
        {headerImage && (
          <Img fluid={headerImage.childImageSharp.header} alt={`${title} cover image`} />
        )}
        <Outer bg="white">
          <Inner pt={60} pb={0} maxWidth={700}>
            <Box variant="header4">
              <Link to="/blog" color="blue">
                Sprintlio Blog
              </Link>{' '}
              /{' '}
              <Link to={post.frontmatter.slug} color="blue">
                {title}
              </Link>
            </Box>
            <H1 variant="largeTitle" mt={15} color="black">
              {title}
            </H1>
            <Flex mt={15}>
              <Box variant="header3" color="mediumGrey" flex="1">
                {date}
              </Box>
              <TwitterShareButton url={location.href}>
                <SocialIconWrap>
                  <Social.Twitter />
                </SocialIconWrap>
              </TwitterShareButton>
              <LinkedinShareButton url={location.href}>
                <SocialIconWrap>
                  <Social.Linkedin />
                </SocialIconWrap>
              </LinkedinShareButton>
              <FacebookShareButton url={location.href}>
                <SocialIconWrap>
                  <Social.Facebook />
                </SocialIconWrap>
              </FacebookShareButton>
            </Flex>

            <BlogStyleWrap mt={40}>
              <MDXProvider components={{ ...CMS_COMPONENTS, ...CMS_SHORTCODES }}>
                <MDXRenderer>{post.body}</MDXRenderer>
              </MDXProvider>
            </BlogStyleWrap>

            <Grid
              mt={40}
              gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}
              gridGap={20}
              variant="body2"
              sx={{ a: { flex: 1, '&:hover': { textDecoration: 'underline' } } }}
            >
              {previous && (
                <Link
                  to={previous.frontmatter.slug}
                  rel="prev"
                  display="flex"
                  alignItems="baseline"
                  gridColumn="1 / 2"
                >
                  <Box mr={10} flexShrink="0">
                    ←
                  </Box>
                  <div>{previous.frontmatter.title}</div>
                </Link>
              )}
              {next && (
                <Link
                  to={next.frontmatter.slug}
                  rel="next"
                  display="flex"
                  alignItems="baseline"
                  gridColumn="2 / 3"
                  justifySelf="end"
                >
                  <Box textAlign="right">{next.frontmatter.title}</Box>
                  <Box ml={10} flexShrink={0}>
                    →
                  </Box>
                </Link>
              )}
            </Grid>
          </Inner>
        </Outer>
        <Outer bg="white">
          <Inner pt={80}>
            <LatestPosts />
          </Inner>
        </Outer>
      </Box>
      <Footer />
    </BaseLayout>
  );
};

export default BlogPostTemplate;

BlogPostTemplate.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.shape({}).isRequired,
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
  pageContext: PropTypes.shape({
    next: PropTypes.shape({}),
    previous: PropTypes.shape({}),
  }).isRequired,
};
