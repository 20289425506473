import React from 'react';

import {
  MDLink,
  ImageCaption,
  LargeQuote,
  LeftFloatImg,
  YouTube,
} from '../components/blog/style-wrap';

export const CMS_COMPONENTS = {
  a: (props) => <MDLink {...props} />,
};

export const CMS_SHORTCODES = {
  ImageCaption,
  LargeQuote,
  LeftFloatImg,
  YouTube,
};

export default CMS_SHORTCODES;
