import PropTypes from 'prop-types';
import React from 'react';
import YouTubePlayer from 'react-youtube';
import styled from 'styled-components';

import { Link } from 'gatsby';
import Box from '../Box';

export const MDLink = ({ href, ...props }) => {
  if (href.startsWith('/')) {
    return <Link to={href} {...props} />;
  }
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a href={href} {...props} />;
};
MDLink.propTypes = {
  href: PropTypes.string.isRequired,
};

export const LeftFloatImg = ({ src, alt }) => (
  <Box
    as="figure"
    width={['100%', '100%', '70%']}
    ml={[0, 0, -120]}
    mr={[0, 0, 20]}
    mt={30}
    textAlign="center"
    sx={{ float: ['none', 'none', 'left'], img: { maxWidth: '100%' } }}
  >
    <img src={src} alt={alt} />
  </Box>
);
LeftFloatImg.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export const ImageCaption = (props) => (
  <Box variant="body2" color="mediumGrey" textAlign="center" mt="5px" {...props} />
);

export const LargeQuote = (props) => {
  return (
    <Box
      as="blockquote"
      className="largeQuote"
      mt={20}
      px={[40, 80]}
      color="mediumGrey"
      fontSize="2.4rem"
      fontWeight="500"
      lineHeight="1.8em"
      fontStyle="italic"
      {...props}
    />
  );
};

const StyledYouTubePlayer = styled(({ className, ...props }) => (
  <YouTubePlayer containerClassName={className} {...props} />
))`
  margin-top: 20px;
  width: 100%;
  height: 0;
  padding-top: 56.25%; // 16:9
  position: relative;
  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
  }
`;
export const YouTube = (props) => <StyledYouTubePlayer {...props} />;

const BlogStyleWrap = styled(Box)`
  font-size: 1.7rem;
  line-height: 1.8em;
  p {
    margin-top: 20px;
    .gatsby-resp-image-wrapper {
      margin-top: 40px;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${(props) => props.theme.colours.black};
  }
  h2 {
    font-size: 2.4rem;
    margin-top: 40px;
  }
  h3 {
    font-size: 2.1rem;
    margin-top: 30px;
  }
  h4 {
    font-size: 1.9rem;
    margin-top: 30px;
  }
  a {
    text-decoration: underline;
  }
  blockquote:not(.largeQuote) {
    margin-left: -20px;
    padding-left: 23px;
    border-left: 3px solid black;
    font-style: italic;
  }
  ol {
    padding-left: 25px;
    li {
      margin-top: 10px;
    }
  }
  ul {
    padding-left: 25px;
    li {
      margin-top: 10px;
    }
  }
  hr {
    height: auto;
    border: none;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 24px;
    color: #000;
    text-align: center;
    &::before {
      content: '...';
      box-sizing: inherit;
      line-height: 1.4em;
      letter-spacing: 0.5em;
    }
  }
`;

export default BlogStyleWrap;
